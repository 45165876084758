@import '~@angular/material/theming';
@include mat-core();

$rm-blue-palette: (
	50: #e2f2fd,
	100: #b6dffa,
	200: #0b93ef,
	300: #54b3f4,
	400: #30a3f1,
	500: #0b93ef,
	600: #0a8bed,
	700: #0880eb,
	800: #0676e8,
	900: #0364e4,
	A100: #ffffff,
	A200: #d8e7ff,
	A400: #a5c7ff,
	A700: #8bb7ff,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);

$primary: mat-palette($rm-blue-palette, 200);
$accent: mat-palette($rm-blue-palette, 200);

$theme: mat-light-theme($primary, $accent);

@include angular-material-theme($theme);

.mat-badge-accent {
	.mat-badge-content {
		background: #ff1d00;
		color: #ffffff;
	}
}
