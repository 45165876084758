

:root {
	--color-1: #0b93ef;
	--color-green: #3ed598;
	--color-grey-metauro: #aeb8c0;
	--color-red: #fa3c4c;
	--color-violet: #8962f8;
	--color-orange: #ff9900;

    --app-height: 100%;

    /* Image cropper */
    --cropper-outline-color: rgba(0,0,0,0.3);
}

/* Image cropper */
.ngx-ic-overlay {
    z-index: 1;
}

html,
body {
	height: 100vh;
    height: var(--app-height);
}

body {
	margin: 0;
	font-family: Inter, 'Helvetica Neue', sans-serif;
	letter-spacing: 1px !important;
	font-size: 15px;
}

.no-padding {
	padding: 0px !important;
	overflow: hidden;
}

.no-padding-bottom {
	padding-bottom: 0px !important;
}

.no-margin {
	margin: 0px !important;
}

.no-margin-top {
	margin-top: 0px !important;
}

.no-margin-bottom {
	margin-bottom: 0px !important;
}

.margin-8-2 {
	margin-top: 8px !important;
	margin-bottom: 2px !important;
}

.margin-top-5 {
	margin-top: 5px;
}

.margin-top-15 {
	margin-top: 15px;
}

.margin-left-20 {
	margin-left: 20px;
}

.v-spacing {
	padding-top: 10px !important;
}

.v-spacing-5 {
	padding-top: 5px !important;
}

.v-spacing-20 {
	padding-top: 20px !important;
}

.v-spacing-30 {
	padding-top: 30px !important;
}

.side-padded {
    padding-left: 20px;
    padding-right: 20px;
}

.h-spacing {
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.clickable {
	cursor: pointer !important;
}

.link-clickable {
	cursor: pointer !important;
}

.link-clickable:hover {
	text-decoration: underline;
}

#modal-title {
	margin-top: 0px;
	margin-left: 10px !important;
}

.dialog-footer {
	margin-top: 80px;
	margin-bottom: 20px;
}

.dialog-actions-spacer {
	flex: 1 1 auto;
}

.dialog-fixed-footer {
	border-top: 1px solid #00000015;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.menu-info-label {
	margin-right: 10px;
	margin-top: 5px;
	margin-bottom: 0px;
}

/*	SECTION VIEWS
	------------------------------- */

.section-white {
	background-color: white;
	padding: 15px;
}

.section-v-tabs {
	background-color: white;
	padding: 15px;
}

.section-blue-border {
	border: 2px solid #0b93ef;
	padding: 15px;
}

.blue-border {
	border: 2px solid #0b93ef;
}

/*	------------------------------- */

/*	IMAGES
	------------------------------- */

.img-avatar {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.img-avatar-big {
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.img-avatar-small {
	width: 35px;
	height: 35px;
	border-radius: 50%;
}

.img-avatar-20 {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.img-landlord {
	height: 30px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}

.img-item {
	border-radius: 50%;
	height: 30px;
	width: 30px;
	margin-right: 10px;
}

.img-icon {
	height: 20px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}

.img-icon-28 {
	height: 28px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}

.img-icon-with-bg {
	height: 20px;
	max-width: 20px;

	display: inline-block;
	vertical-align: middle;

	margin-right: 10px;

	padding: 5px;
	border-radius: 12px;

	background-color: white;
}

.img-icon-no-margin {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: middle;
}

.img-close {
	width: 15px;
}

.img-btn {
	height: 30px;
	display: inline-block;
	vertical-align: middle;

	margin-left: 20px;
}

.select-label-image {
	padding-left: 5px;
}

.select-label-image-2 {
	padding-left: 5px;
	margin-top: -10px;
}

.img-property {
	width: 50px;
	height: 50px;
}

/*	------------------------------- */

/*	SELECTION CARDS
	------------------------------- */

.img-item-card {
	width: 50px;
	height: 50px;

	min-width: 50px;
	min-height: 50px;

	border-radius: 8px;

	margin-right: 5px;
}

.img-item-card-left {
	width: 80px;
	height: 150px;

	min-width: 80px;
	min-height: 150px;

	margin-right: 10px;

	object-fit: cover;
}

.title-item-card {
	display: block;
	text-overflow: ellipsis;
	word-wrap: break-word;
	overflow: hidden;
	max-height: 36px;
}

.address-item-card {
	display: block;
	text-overflow: ellipsis;
	word-wrap: break-word;
	overflow: hidden;
	min-height: 54px;
	max-height: 54px;
}

.address-item-card-2 {
	display: block;
	text-overflow: ellipsis;
	word-wrap: break-word;
	overflow: hidden;

	height: auto;
	max-height: 54px;
}

.button-item-card {
	color: #0b93ef !important;
	border: 1px solid #0b93ef !important;
	border-radius: 8px !important;
	background-color: #ffffff !important;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	width: 100%;

	height: 40px;

	font-family: Inter;
	font-size: 15px;
	font-weight: bold;
	letter-spacing: 1.61px;
	line-height: 15px;
}

.red-close-button {
	color: #fa3c4c !important;
	border: 1px solid #fa3c4c !important;
	border-radius: 3px;
	background-color: #ffffff !important;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	width: 30px !important;
	min-width: 30px !important;
	height: 30px !important;
	padding: 0px !important;
	line-height: 0px !important;
}

/*	------------------------------- */

/*	TYPOGRAPHY
	------------------------------- */

.section-title {
	height: 20px;
	color: #042759;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 0.19px;
	line-height: 20px;
}

.section-desc {
	height: 20px;
	color: #aeb8c0;
	font-size: 15px;
	font-style: italic;
	line-height: 20px;

	letter-spacing: 0.19px;
	font-weight: 300;
}

.section-desc-long {
	color: #aeb8c0;
	font-size: 15px;
	font-style: italic;
	line-height: 20px;

	letter-spacing: 0.19px;
	font-weight: 300;
}

.text-center {
	text-align: center;
}

.italic {
	font-style: italic;
}

.italic-20 {
	color: #79828b;
	font-size: 20px;
	line-height: 27px;
}

.semi-bold {
	font-weight: 500;
}

.bold-underlined {
	text-decoration: underline;
	font-weight: 500;
}

.light-15 {
	color: #042759;
	font-family: Inter;
	font-size: 15px;
	letter-spacing: 1px;
	line-height: 18px;
	font-weight: 300;
}

.regular-8 {
	color: #042759;
	font-family: Inter !important;
	font-size: 8px !important;
	letter-spacing: 1px !important;
	line-height: 12px !important;
	font-weight: 300 !important;
}

.regular-10 {
	color: #042759;
	font-family: Inter !important;
	font-size: 10px !important;
	letter-spacing: 1px !important;
	line-height: 14px !important;
	font-weight: 300 !important;
}

.semi-bold-8 {
	color: #042759;
	font-size: 8px !important;
	letter-spacing: 1px !important;
	line-height: 12px !important;
	font-weight: 500;
}

.semi-bold-10 {
	color: #042759;
	font-family: Inter;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 14px;
}

.regular-12 {
	color: #042759;
	font-family: Inter !important;
	font-size: 12px !important;
	letter-spacing: 1px !important;
	line-height: 20px !important;
	font-weight: 300 !important;
}

.regular-13 {
	color: #042759;
	font-family: Inter !important;
	font-size: 13px !important;
	letter-spacing: 1px !important;
	line-height: 15px !important;
	font-weight: 300 !important;
}

.regular-15 {
	color: #042759;
	font-family: Inter !important;
	font-size: 15px !important;
	letter-spacing: 1px !important;
	line-height: 22px !important;
	font-weight: 300 !important;
}

.light-10 {
	color: #042759;
	font-family: Inter;
	font-size: 10px;
	letter-spacing: 1px;
	line-height: 18px;
	font-weight: 300;
}

.light-12 {
	color: #042759 !important;
	font-family: Inter !important;
	font-size: 12px !important;
	letter-spacing: 1px !important;
	line-height: 18px !important;
	font-weight: 300 !important;
}

.semi-bold-11 {
	color: #042759;
	font-family: Inter;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 14px;
}

.semi-bold-12 {
	color: #042759;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 15px;
}

.semi-bold-13 {
	color: #042759;
	font-family: Inter;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 15px;
}

.semi-bold-15 {
	color: #042759;
	font-family: Inter;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 18px;
}

.semi-bold-18 {
	color: #ffffff;
	font-family: Inter;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 15px;
	text-align: right;
}

.regular-20 {
	color: #042759;
	font-family: Inter !important;
	font-size: 20px !important;
	line-height: 20px !important;
}

.semi-bold-20 {
	color: #042759;
	font-family: Inter;
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 20px;
	text-align: right;
}

.light-25 {
	color: #042759;
	font-family: Inter;
	font-size: 25px;
	letter-spacing: 1px;
	line-height: 18px;
	font-weight: 300;
}

.semi-bold-25 {
	color: #042759;
	font-family: Inter;
	font-size: 25px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 25px;
}

.detail-title {
	color: #aeb8c0;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold;
	line-height: 18px;
}

.link-text {
	color: #0b93ef;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 18px;
	text-decoration: underline;
}

.sub-section-title {
	color: #0b93ef;
	font-family: Inter;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 18px;
}

.form-label {
	color: #aeb8c0;
	font-family: Inter;
	font-size: 15px;
	line-height: 18px;

	letter-spacing: 1px !important;
	font-weight: 350 !important;
}

.big-label {
	color: #042759;
	font-family: Inter;
	font-size: 30px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 58px;
}

.regular-30 {
	color: #042759;
	font-family: Inter;
	font-size: 30px;
	letter-spacing: 1px;
	line-height: 30px;
}

.registration-invite-label {
	color: #042759;
	font-family: Inter;
	font-size: 15px;
	letter-spacing: 1px;
	line-height: 18px;
	font-weight: 300;
}

@media screen and (max-width: 1300px) {
	.registration-invite-label {
		font-size: 12px;
	}
}

/*	------------------------------- */

/*	COLORS
	------------------------------- */

.color-1 {
	color: #0b93ef !important;
}

.color-2 {
	color: #00bff2 !important;
}

.color-green {
	color: #3ed598;
}

.color-green-2 {
	color: #00c8b6;
}

.color-red {
	color: #fa3c4c !important;
}

.color-grey {
	color: #aeb8c0 !important;
}

.color-violet {
	color: #8962f8;
}

.color-white {
	color: white !important;
}

.color-back-1 {
	background-color: #f2f7fe;
}

.color-orange {
	color: #ff9900 !important;
}

.color-priority-0 {
	color: #008e3d !important;
}

.color-priority-1 {
	color: #3ed598 !important;
}

.color-priority-2 {
	color: #ff9900 !important;
}

.color-priority-3 {
	color: #fa3c4c !important;
}

/*	------------------------------- */

/*	BUTTONS
	------------------------------- */

.btn-1 {
	color: #0b93ef !important;
	border: 1px solid #0b93ef !important;
	border-radius: 3px;
	background-color: #ffffff;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	width: 160px;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 15px;
}

.button-1 {
	color: #0b93ef !important;
	border: 1px solid #0b93ef !important;
	border-radius: 3px;
	background-color: #ffffff !important;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	/*width: 180px; Perdonami Francesco */
	min-width: 180px !important;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 15px;
}

.button-2 {
	color: #0b93ef !important;
	border: 1px solid #0b93ef !important;
	border-radius: 3px;
	background-color: #ffffff;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	width: 120px;
	min-width: 120px !important;
	height: 35px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 15px;
}

.button-cancel {
	color: #fa3c4c !important;
	border: 1px solid #fa3c4c !important;
	border-radius: 3px;
	background-color: #ffffff;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	/* width: 180px; e anche qui... */
	min-width: 180px !important;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 15px;
}

.button-back {
	color: #aeb8c0 !important;
	border: 1px solid #aeb8c0 !important;
	border-radius: 3px;
	background-color: #ffffff;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	width: 180px;
	min-width: 180px !important;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 15px;
}

.button-green {
	color: #3ed598;
	border: 1px solid #3ed598 !important;
	border-radius: 3px;
	background-color: #ffffff;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	/* width: 180px; */
	min-width: 180px !important;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 15px;
}

.button-blue {
	color: #0b93ef;
	border: 1px solid #0b93ef !important;
	border-radius: 3px;
	background-color: #0b93ef !important;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	/* width: 180px; */
	min-width: 180px !important;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 15px;
}

.button-red {
	color: #fa3c4c !important;
	border: 1px solid #fa3c4c !important;
	border-radius: 3px;
	background-color: #ffffff;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	/* width: 180px; */
	min-width: 180px !important;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 15px;
}

.button-orange {
	color: #FF9900;
	border: 1px solid #FF9900 !important;
	border-radius: 3px;
	background-color: #ffffff;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	/* width: 180px; */
	min-width: 180px !important;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 15px;
}

.button-image {
	color: #0b93ef !important;
	border: 1px solid #0b93ef !important;
	border-radius: 3px;
	background-color: #ffffff;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	width: 180px;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 15px;

	padding-left: 10px !important;
	padding-right: 10px !important;
}

.button-image-2 {
	color: #0b93ef !important;
	border: 1px solid #0b93ef !important;
	border-radius: 3px;
	background-color: #ffffff;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	/* WIDTH NOT SET */
	min-width: 180px;

	height: 40px;

	font-family: Inter;
	font-size: 15px;
	font-weight: bold !important;
	letter-spacing: 1px;
	line-height: 15px;

	padding-left: 10px !important;
	padding-right: 10px !important;
}

.button-image-2-img {
	width: 20px;
	height: 20px;
}

.button-image mat-icon {
	margin-right: 3px !important;
}

.button-underline {
	color: #0b93ef;

	font-family: Inter;
	font-size: 15px;
	letter-spacing: 1px;
	line-height: 18px;
	font-weight: 500;

	text-decoration: underline;

	cursor: pointer;
}

.button-room-select {
	height: 80px;
	width: 180px;
	border: 2px solid #aeb8c078;
	border-radius: 8px;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	color: #aeb8c0;

	outline: none;

	margin-right: 12px;
	margin-bottom: 10px;

	font-family: Inter;
	font-size: 15px;
	letter-spacing: 1px;
	line-height: 18px;

	font-weight: 300;
}

.button-room-select-4 {
	height: 80px;
	width: 180px;
	border: 2px solid #aeb8c078;
	border-radius: 8px;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	color: #aeb8c0;

	outline: none;

	font-family: Inter;
	font-size: 15px;
	letter-spacing: 1px;
	line-height: 18px;

	font-weight: 300;
}

.button-room-select-no-l-border {
	border-left: 0px;
}

.button-card {
	margin-left: 20px;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

/*	------------------------------- */

/*	FORM FIELDS
	------------------------------- */

form {
	width: 100%;
}

.field-full-width {
	width: 100%;
	/* To be used in a flex container */
}

.field-180 {
	width: 180px;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
	color: white !important;
}

.mat-step-header .mat-step-icon {
	color: white !important;
}

input-container.input-focused:not([md-no-float]) .select-placeholder span:first-child {
	-webkit-transform: none;
	transform: none;
}

/*  CHAT
    ------------------------------- */

.msg-row {
	margin-top: 10px;
	margin-bottom: 10px;

	margin-left: 15px;
	margin-right: 15px;
}

.msg-bubble-received {
	background-color: #ededed;
	color: #042759;

	padding: 10px 20px 10px 20px;

	border-radius: 0 20px 20px 20px;

	margin-left: 10px;
	margin-right: 10px;

	white-space: pre-line;

	overflow-wrap: anywhere;

	min-width: 20%;
	max-width: 60%;
}

.msg-bubble-sent {
	background-color: #0b93ef;
	color: white;

	padding: 10px 20px 10px 20px;

	border-radius: 20px 0 20px 20px;

	margin-left: 10px;
	margin-right: 10px;

	white-space: pre-line;

	overflow-wrap: anywhere;

	min-width: 20%;
	max-width: 60%;
}

.msg-time {
	align-self: flex-start;
	min-width: 80px;
	display: flex;
}

/*	------------------------------- */

/*  TABLE HEADER
    ------------------------------- */

.mat-sort-header-arrow {
	color: white !important;
}

/*	------------------------------- */

/*  CARD (shadow)
    ------------------------------- */

mat-card {
	box-shadow: none !important;

	border-radius: 12px !important;
}

/*	------------------------------- */

/*  TOOLTIP (textSize)
    ------------------------------- */

.mat-tooltip {
	font-size: 15px !important;
	/* My first important :') */
}

/*	------------------------------- */

/*  PHONE PICKER
    ------------------------------- */

.iti__selected-flag:hover {
	background-color: transparent !important;
}

.iti__selected-flag:active {
	outline: none;
}

/*	Vertical centered label in home ------------------------------- */

.vert-centered {
	align-self: flex-start;
}

/*	Remove underline from link label in home ------------------------------- */

.no-underlined {
	text-decoration: none;
}

.mat-button.status-invited {
	color: #ffffff !important;
	background: #3ed598;
	min-width: 180px !important;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold;
	letter-spacing: 1.61px;
	line-height: 15px;
}

.mat-button.status-not-invited {
	color: #ffffff !important;
	background: #fa3c4c;
	min-width: 180px !important;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold;
	letter-spacing: 1.61px;
	line-height: 15px;
}

/* ----------------- */
/* Estelle v. 0.2.1 */

.lbl-property-info {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-height: 18px;
}

.lbl-property-info-2rows {
	display: block;
	text-overflow: ellipsis;
	word-wrap: break-word;
	overflow: hidden;
	max-height: 36px;
}

app-property-profile {
	width: 100%;
}

app-property-leases {
	width: 100%;
}

app-property-balance {
	width: 100%;
}

app-property-files {
	width: 100%;
}

app-property-gallery {
	width: 100%;
}

app-tenant-profile {
	width: 100%;
}

app-tenant-leases {
	width: 100%;
}

app-tenant-checklists {
	width: 100%;
}

app-tenant-payments {
	width: 100%;
}

app-tenant-files {
	width: 100%;
}

app-tenant-chat {
	width: 100%;
}

app-property-chat {
	width: 100%;
}

app-settings-general {
	width: 100%;
}

app-settings-bank {
	width: 100%;
}

app-chat-private {
	width: 100%;
}

app-chat-property {
	width: 100%;
}

app-maintenance-profile {
	width: 100%;
}

app-maintenance-files {
	width: 100%;
}

app-settings-invoicing {
	width: 100%;
}

app-dash-label-simple {
	overflow: hidden;
	width: 100%;
}

app-section-top-overview {
	width: 100%;
}

app-lease-item {
	width: 100%;
}

app-property-card {
	width: 100%;
	margin-left: 5px;
	margin-right: 5px;
	overflow: hidden;
}

app-tenant-card {
	width: 100%;
	margin-left: 5px;
	margin-right: 5px;
	overflow: hidden;
}

app-property-unit-past-leases {
	width: 100%;
}

app-property-unit-lease {
	width: 100%;
}

app-property-unit-data {
	width: 100%;
}

app-property-data {
	width: 100%;
}

app-address-form {
	width: 100%;
}

app-tenant-lease {
	width: 100%;
}

app-tenant-data {
	width: 100%;
}

app-tenant-app {
	width: 100%;
}

app-tenant-past-leases {
	width: 100%;
}

app-roommate-bulk-actions {
	position: absolute;
	bottom: 60px;
	left: calc(50% - 230px);
}

#rooms-container {
	width: 180px;
	max-width: 180px;

	padding: 20px;

	margin-right: 35px;

	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	box-sizing: content-box;
}

#rooms-container-row {
	padding: 20px 0px 0px 20px;

	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	box-sizing: content-box;
}

.room-selection-btn {
	width: 100%;
	height: 40px;

	margin-bottom: 20px;

	background-color: white !important;

	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	cursor: pointer;
}

.lbl-room-name {
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	min-height: 18px;
	height: 18px;
	white-space: nowrap;
	width: 180px;
	text-align: center;
}

.room-selection-btn-row {
	min-width: 140px;
	max-width: 140px;
	height: 40px;

	margin-bottom: 20px;
	margin-right: 20px;

	background-color: white !important;

	border-radius: 8px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	cursor: pointer;
}

.room-selection-btn-row .lbl-room-name {
	width: 140px;
}

.room-selection-btn .lbl-room-name {
	color: #00bff2 !important;
}

.room-selection-btn-active {
	background-color: #00bff2 !important;
}

.room-selection-btn-active .lbl-room-name {
	color: white !important;
}

.mat-menu-item {
	height: 40px;
	max-height: 40px;
}

.filter-number {
	font-size: 20px;
	color: #3ed598;
	margin-left: 5px;
}

.cdk-overlay-dark-backdrop {
	opacity: 0 !important;
}

/* SEARCH BAR IMAGE */

.img-icon-search {
	height: 20px;
	display: inline-block;
	vertical-align: middle;

	margin-left: 30px;
	margin-right: 10px;
}

/* ---------------- */

.shadow-2 {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25) !important;
}

/* NO CORNER RADIUS IN DIALOGS */

/* Bold for long texts */
strong {
	font-weight: 500;
}

/* Allow dark background on dialogs while avoiding it in list selector overlay */
.backdrop-dark-background {
	background: rgba(0, 0, 0, 0.32);
	opacity: 1 !important;
}

.mat-dialog-container {
	border-radius: 12px !important;
}

/* No border on lateral containers */
.lateral-dialog-container .mat-dialog-container {
	border-radius: 0px !important;
}

/* Multi - photo classes */

.mat-radio-checked {
	color: #042759;
	font-weight: 400 !important;
}

.category-menu-item {
	place-content: start !important;
	font-size: 13px !important;
	max-height: 35px;
}

.mat-menu-panel {
	min-height: 0px !important;
}

/* READONLY INPUT */

.readonly-input {
	border: 1px solid #aeb8c0;
	box-sizing: border-box;

	border-radius: 3px;

	padding-left: 10px;
	padding-right: 10px;

	padding-top: 4px;

	width: calc(100% - 20px);
	height: 40px;
	color: #aeb8c0;
	font-family: Inter;
	font-size: 15px;
	letter-spacing: 0.19px;
	line-height: 18px;
	font-weight: 300;
	font-style: italic;
	outline: none;
}

.uppercase {
	text-transform: uppercase;
}

/* --------------------------- */

.timepicker-overlay {
	z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
	z-index: 1100 !important;
}

.grey-bg {
	background: rgba(242, 242, 242, 0.5);
}

.lat-section-title {
	font-family: Inter;
	font-size: 15px;
	letter-spacing: 1px;
	line-height: 18px;
	font-weight: 300;
	color: #aeb8c0;
}

.lat-section-title strong {
	color: #00bff2;
}

.strong-green strong {
	color: #3ed598;
}

.strong-red strong {
	color: #fa3c4c;
}

.rm-tag-view {
	height: 24px;
	padding: 0px 10px 0px 10px;
	border-radius: 6px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
}

.rm-tag-view p {
	margin: 0px !important;
	letter-spacing: 0px;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100px;
	white-space: nowrap;
}

.tag-mock {
	background-color: var(--color-violet);
	color: white;
}

.tag-green {
	background-color: var(--color-green);
	color: white;
}

.tag-red {
	background-color: var(--color-red);
	color: white;
}

.tag-loading {
	background-color: #aeb8c0;
	color: white;
}

.tag-primary {
	background-color: var(--color-1);
	color: white;
}

.tag-starting {
	background-color: var(--color-orange);
	color: white;
}

.tag-priority-0 {
	background-color: #008e3d;
	color: white;
}

.tag-priority-1 {
	background-color: #3ed598;
	color: white;
}

.tag-orange {
	background-color: #ff9900;
	color: white;
}

.tag-priority-3 {
	background-color: #fa3c4c;
	color: white;
}

.tag-refusal {
	background-color: #d8d8d8;
	color: white;
}

.rm-tag-view.tag-disabled {
	background-color: #e8f0fe;
	color: #aeb8c0;
}

.mat-progress-spinner.mock-spinner circle,
.mat-spinner circle {
	stroke: #ffffff;
}

.mat-progress-spinner.file-spinner circle,
.mat-spinner circle {
	stroke: #0b93ef;
	display: inline-block;
}

.suggestions-title {
	color: #8962f8;
	font-weight: 500;
	position: relative;
}

.suggestions-title::after {
	content: '';
	background: #edf1fa;
	position: absolute;
	bottom: 10px;
	height: 1px;
	border-bottom: 1px solid #edf1fa;
	width: 100%;
	left: 0;
}



/* LOADING */

@import '~ng-snotify/styles/material';
@import '~@angular/cdk/overlay-prebuilt.css';

$primary-color: #0b93ef;
$secondary-color: #edf1fa;
$disabled-grey: #aeb8c0;
$main-green: #3ed598;
$main-red: #fa3c4c;
$main-orange: #ff9900;

$top-bar-height: 50px;

/* This are the stiles that define how lateral dialog are displayed in Estelle */
.clickable {
	cursor: pointer;
}

.new-lateral-dialog-container .mat-dialog-container {
	border-radius: 0px !important; // Chek if important is mandatory
	padding: 0px; // Override material dialogs padding
	/* Main container */
	.lateral-container {
		height: 100vh;
		display: flex;
		flex-direction: column;
	}
	.content {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		overflow-y: auto;
	}
	.lateral-top-bar {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: $secondary-color;
		height: 60px;
		flex-shrink: 0; //Avoid reducing in size when content is scrollable
		p {
			flex-grow: 1;
			font-family: Inter;
			font-size: 15px;
			letter-spacing: 0.25px;
		}
		.strong {
			font-weight: 500;
		}
		.icon {
			margin-left: 20px;
			margin-right: 20px;
			width: 30px;
		}
		.close {
			padding: 10px 20px;
			cursor: pointer;
			height: 15px;
		}
	}
	.lateral-bottom-bar {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 20px;
		align-items: center;
		background-color: $secondary-color;
		padding-right: 20px;
		padding-left: 20px;
		height: 75px;
		flex-shrink: 0; //Avoid reducing in size when content is scrollable
		.separator {
			flex-grow: 1;
		}
	}
}

.section {
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 20px;

	.title {
		color: #0b93ef;
		font-size: 15px;
		margin-bottom: 10px;
		margin-top: 0px;
	}
	.desc {
		color: #042759;
		font-family: Inter !important;
		font-size: 12px !important;
		letter-spacing: 1px !important;
		line-height: 20px !important;
		font-weight: 300 !important;

		margin-bottom: 20px; // Margin will collapse with top margins of lower elements
	}
	p {
		margin-top: 10px;
		margin-bottom: 0px;
		font-size: 12px;
	}
	.size-15 {
		font-size: 15px;
	}
}

.centered-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.right-aligned-block {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

%standard-button {
	min-width: 150px;
	min-height: 50px;
	font-size: 20px;
	font-weight: bold !important;
	border-radius: 6px;
	box-shadow: none;
	border: none;
}

.mat-button.button-positive {
	@extend %standard-button;
	color: white;
	background: $main-green;
}

.mat-button.button-negative {
	@extend %standard-button;
	color: white;
	background: $main-red;
}

.mat-button.button-negative-empty {
	@extend %standard-button;
	color: $main-red;
	background: white;
	border: 1px solid $main-red;
}

.mat-button.button-primary {
	@extend %standard-button;
	color: white;
	background: $primary-color;
}

.mat-button.button-neutral {
	@extend %standard-button;
	color: white;
	background: $disabled-grey;
}

.mat-button.button-neutral-empty {
	@extend %standard-button;
	color: $disabled-grey;
	background: white;
	border: 1px solid $disabled-grey;
}

.mat-button.button-orange {
	@extend %standard-button;
	color: white;
	background: $main-orange;
}

.mat-button.button-cancel {
	color: #fa3c4c !important;
	border: 1px solid #fa3c4c !important;
	border-radius: 8px;
	background-color: #ffffff;

	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

	/* width: 180px; e anche qui... */
	min-width: 180px !important;
	height: 40px;
	font-family: Inter;
	font-size: 15px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 15px;
}

.mat-button[disabled][disabled].button-positive,
.mat-button[disabled][disabled].button-primary,
.mat-button[disabled][disabled].button-negative,
.mat-button[disabled][disabled].button-negative-empty,
.mat-button[disabled][disabled].button-neutral,
.mat-button[disabled][disabled].button-neutral-empty {
	color: white;
	background: $disabled-grey;
}

.mat-button.button-small {
	min-width: 100px;
	text-transform: none;
	font-size: 12px;
	padding-left: 10px;
	padding-right: 10px;
	min-height: 30px;
	height: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.btn-empty-screen {
	width: 220px;
	height: 60px;
	text-transform: none !important;
	font-size: 20px !important;
}

.link {
	text-decoration-line: underline;
	color: $primary-color;
	font-weight: 500;
	cursor: pointer;
}

/*
table {
	width: 100%;
	background-color:transparent!important; // Needed for border rounding
	border-collapse: separate;
	margin-bottom: 20px;

	tr:last-child td:first-child { border-bottom-left-radius: 24px; }
	tr:last-child td:last-child { border-bottom-right-radius: 24px; }

	tr.mat-header-row {
		height: 30px;
	}

	td.mat-cell {
		height: 45px;
		background-color: $secondary-color;
		border-bottom: 1px solid #AEB8C0;
	}

	tr.mat-row:last-child td.mat-cell {
		border-bottom: none;
	}

	.mat-header-cell {
		color: white;
		font-size: 15px;
		background: $primary-color;
	}

	.mat-header-cell:first-child {
		border-top-left-radius: 24px;
	}

	.mat-header-cell:last-child {
		border-top-right-radius: 24px;
	}

	.error-text td.mat-cell{
		color: #FA3C4C !important;
		font-weight: 500 !important;
	}

	.highligthed-text td.mat-cell{
		color: $primary-color !important;
		font-weight: 500 !important;
	}

}
*/

p {
	.green {
		color: $main-green;
	}
}

.alert-box {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 12px;
	img {
		margin-right: 10px;
		flex-shrink: 0;
	}
}

/* All the outlined mat-form with a class dash-form-field will be 40px high instead of 60px */

.mat-form-field {
	.mat-form-field-flex > .mat-form-field-infix {
		padding: 0.4em 0px !important;
	}
	.mat-form-field-label-wrapper {
		top: -1.5em;
	}
	&.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
		transform: translateY(-1.1em) scale(0.75);
	}
	.mat-form-field-wrapper {
		padding-bottom: 0;
	}
}

/*

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float.dash-form-field .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75);
    width: 133.33333%;
}

.dash-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0px;
    margin: 0px 0px 20px 0px; // We won't have space for floating label. We'll make the space by ourselves
}

.dash-form-field.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.4em 0px
}


.dash-form-field.mat-form-field-appearance-outline .mat-form-field-label-wrapper {
    top: -1.5em;
}

*/

.dash-form-field-add.mat-form-field-appearance-outline .mat-form-field-wrapper {
	padding-bottom: 0px;
	margin: 0px 0px 20px 0px; // We won't have space for floating label. We'll make the space by ourselves
}

/* Version with more margin */
/*

.dash-form-field-add.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.4em 0px
}


.dash-form-field-add.mat-form-field-appearance-outline .mat-form-field-label-wrapper {
    top: -1.5em;
}

*/
.no-padding-dialog {
	.mat-dialog-container {
		padding: 0;
		margin: 0;
	}
}

.no-padding-modalbox {
	.mat-dialog-container {
		padding: 0px;
		border-radius: 25px !important;
	}
	.mat-dialog-content {
		padding: 0px;
		margin: 0px;
		max-height: 90% !important;
	}
}

.central-dialog-container .mat-dialog-container {
	border-radius: 24px !important;
}

.mat-spinner.spinner-white circle {
	stroke: #ffffff !important;
}

.new-stepper .mat-horizontal-stepper-header-container {
	display: none;
}

.new-stepper .mat-horizontal-content-container {
	padding: 0px;
}

/* New notify toast */
.snotifyToast {
	border-radius: 12px;

	color: #042759;
	font-family: Inter;
	font-size: 15px;
	letter-spacing: 1px;
	line-height: 18px;

	.snotifyToast__progressBar {
		background-color: rgba(255, 255, 255, 0.3);
	}

	.snotifyToast__progressBar__percentage {
		background-color: rgba(255, 255, 255, 1);
	}

	.snotifyToast__title {
		font-size: 20px;
		font-weight: 500;
	}

	.snotifyToast__body {
		color: #ffffff;
		font-size: 15px;
		opacity: 1;
	}

	.snotify-icon--success {
		background-position: center;
		background-image: url('./assets/img/toast_ok.svg');
		background-repeat: no-repeat;
	}

	.snotify-icon--error {
		background-position: center;
		background-image: url('./assets/img/toast_err.svg');
		background-repeat: no-repeat;
	}
}

.snotify-error {
	background-color: $main-red;
}

.snotify-success {
	background-color: $main-green;
}

.snotify-info {
	background-color: #ffffff;

	.snotifyToast__body {
		color: #8962f8;
		font-size: 15px;
		opacity: 1;
	}

	.snotifyToast__buttons button {
		background-color: #8962f8;
		color: #ffffff;
		font-size: 15px;
		opacity: 1;
	}

	.snotify-icon--info {
		background-position: center;
		background-size: 100% !important;
		background-image: url('./assets/img/software_update.svg');
		background-repeat: no-repeat;
	}
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
	min-width: 100%;
	max-width: 100%;
}

.img-action {
	padding: 5px;
	object-fit: scale-down;
}

.notification-icon .mat-badge-active {
	top: -4px !important;
}

.center-popup-header {
	background-color: #042759;
	height: 60px;
}

.center-popup-footer {
	height: 60px;
	width: calc(100% - 40px);
	background-color: #edf1fa;

	padding: 0px 20px 0px 20px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

/* WALLET SCREEN */

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
	color: $disabled-grey !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
	color: #aeaeae !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
	color: #0b93ef !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
	color: #fa3c4c !important;
}

mat-form-field label {
	font-size: 12px !important;
}

mat-form-field.mat-form-field-should-float label {
	font-size: 12px !important;
}

.mat-form-field .mat-form-field-label-wrapper {
	top: -1.1em;
}

.mat-form-field.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
	.mat-form-field-label {
	transform: translateY(-1.2em) scale(0.75) !important;
}

input {
	font-size: 12px !important;
	color: #042759 !important;
}

textarea {
	font-size: 12px !important;
	color: #042759 !important;
}

.mat-select-value-text {
	font-size: 12px !important;
}

.mat-option-text {
	font-size: 12px !important;
}

.mat-checkbox-frame {
	background-color: white !important;
	border: 1px solid #aeb8c0 !important;
}

.roommate-datepicker-icon {
	width: 20px;
	position: absolute;
	right: 0px;
	top: 0px;
	cursor: pointer;
}

.mat-dialog-container {
	color: #042759 !important;
}

.mat-cell,
.mat-footer-cell {
	color: #042759 !important;
}

@keyframes heartbeat {
	0% {
		transform: scale(0.95);
	}
	20% {
		transform: scale(1);
	}
	40% {
		transform: scale(0.95);
	}
	60% {
		transform: scale(1);
	}
	80% {
		transform: scale(0.95);
	}
	100% {
		transform: scale(0.95);
	}
}

@keyframes heartbeat {
	0% {
		transform: scale(0.93);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.93);
	}
}

.spinner-logo {
	width: 150px;
	margin-bottom: 70px;
	animation: heartbeat 0.47s infinite;
}

.loading-text {
	font-size: 20px;
	color: white;
}

/* These are the styles that define the structure of the screens with a lateral navigation/action bar */

.main-content {
	min-height: calc(100vh - #{$top-bar-height});
	max-height: calc(100vh - #{$top-bar-height});
	overflow-y: auto;
	flex-grow: 1;
	padding-right: 40px; /* Lateral general padding */
	display: flex;
}

.inner-content {
	background-color: white;
	border-radius: 12px;
	display: flex;
	flex: 1 1 auto;
	margin: 20px 0px 20px 0px;
	flex-direction: column;
	width: 100%;
}

.navigation-main {
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	overflow: hidden;
}

.navigation-content {
	flex: 1 1 auto;
	display: flex;
	overflow-y: auto;
}

.navigation-lateral {
	flex: 0 0 350px;
	overflow-y: auto;
	padding-right: 20px;
}

.navigation-footer {
	flex: 0 0 60px;
	border-top: 1px solid #edf1fa;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
}

.navigation-page {
	padding: 0px 20px 20px 20px;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	flex: 1 1 auto;
	margin-right: 20px;

	.title {
		color: #0b93ef;
		font-size: 15px;
		margin-bottom: 0px;
		margin-top: 0px;
	}
	.desc {
		color: #042759;
		font-family: Inter !important;
		font-size: 12px !important;
		letter-spacing: 1px !important;
		line-height: 20px !important;
		font-weight: 300 !important;

		margin-bottom: 20px; // Margin will collapse with top margins of lower elements
	}
}

.outlined-red {
	color: $main-red;
	background: white;
	border-radius: 6px;
	border: 1px solid $main-red;
}

.filled-green {
	color: white;
	border-radius: 6px;
	background: $main-green;
}

.popup-top-section {
	background-color: #042759;
	height: 60px;
	position: relative;

	p {
		width: 100%;
		text-align: center;
		position: absolute;
		padding-top: 20px;
	}

	img {
		position: absolute;
		right: 0px;
		cursor: pointer;
		width: 15px;
		height: 15px;
		padding: 20px;
	}
}

.popup-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	img {
		width: 240px;
		margin-top: 20px;
	}

	p {
		letter-spacing: 0.14px;
		font-size: 12px;
		width: 100%;
		text-align: center;
	}
}

.popup-bottom-section {
	background-color: #edf1fa;
	height: 60px;
	display: flex;
	margin-top: 20px;
	width: 100%;
	flex-direction: row;
	padding-left: 20px;
	padding-right: 20px;
	box-sizing: border-box;

	button {
		align-self: center;
		height: 30px;
		min-width: 100px;
	}
}
/* Button Table */

.table-button {
	font-weight: 500;
	font-size: 12px;
	min-width: fit-content;
	width: 60px;
	height: 24px;
	border-radius: 6px;
	line-height: 14px;
	color: white;
	border: none;
}

/*        STATUS STYLES       */
.status-button {
	font-family: Inter;
	border-radius: 6px;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	color: white;
	border: none;
}

.expiring {
	background-color: #0b93ef;
}

.starting,
.pending {
	background-color: #ff9900;
}

.vacant {
	background-color: #fa3c4c;
}

.rented,
.confirmed {
	background-color: #3ed598;
}

.button-disabled {
	background-color: #aeb8c0;
}

// ------------------------------------ //

.filter-item {
	padding: 0px 14px 10px 14px;
	cursor: pointer;
	border-bottom: 3px solid transparent;
}

.filter-item.active {
	border-bottom: 3px solid #0b93ef;
	z-index: 1;
}

%status-label {
	font-weight: bold;
	font-size: 12px;
	min-width: 60px;
	width: fit-content;
	height: fit-content;
	min-height: 24px;
	box-sizing: border-box;
	letter-spacing: 0px;
	padding: 5px;
	border-radius: 6px;
	text-align: center;
	color: white;
	border: none;
}

.status-success {
	@extend %status-label;
	background-color: var(--color-green);
}

.status-fail {
	@extend %status-label;
	background-color: var(--color-red);
}

.status-pending {
	@extend %status-label;
	background-color: var(--color-1);
}

.status-neutral {
	@extend %status-label;
	background-color: #aeb8c0;
}

.status-mock {
	@extend %status-label;
	background-color: var(--color-violet);
}

.status-starting {
	@extend %status-label;
	background-color: var(--color-orange);
}

%status-label.disabled {
	background-color: #e8f0fe;
	color: #aeb8c0;
}

.placeholder-item {
	box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
	border-radius: 4px;
	height: 200px;
	position: relative;
	overflow: hidden;
}

.placeholder-item::before {
	content: '';
	display: block;
	position: absolute;
	left: -200px;
	top: 0;
	height: 100%;
	width: 200px;
	background: linear-gradient(to right, transparent 50%, #fe990030 20%, transparent 100%);
	animation: load 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
	from {
		left: -200px;
	}
	to {
		left: 100%;
	}
}

textarea.mat-input-element {
	line-height: 20px;
}

.locked-scroll {
	overflow: hidden;
}

.ng-image-slider .ng-image-slider-container .main .main-inner .img-div img, .ng-image-slider .ng-image-slider-container .main .main-inner .img-div video {
    object-fit: cover;
}

app-file-picker {
    width: 100%;
}

app-mobile-payment-card {
    width: 100%;
}

app-mobile-maintenance-card {
    width: 100%;
}

app-mobile-payment-operation-card {
    width: 100%;
}

.one-line-text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

app-communications-chat {
    height: 100%;
}